.contact {
    flex: 1;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 1024px) {
        padding: 1rem;
    }
}

.contact-title {
    color: white;
    font-family: "Jersey 15", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 3px darkgoldenrod;
    text-align: center;
    width: 100%;
    @media (min-width: 1024px) {
        font-size: 2.5rem;
        margin-bottom: 150px;
    }
    @media (max-width: 1024px) {
        font-size: 1.8rem;
        margin-block: 20px;
    }
}

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding: 5px;
}

.contact-image-container {
    display: flex;
    align-items: flex-end;
    @media (min-width: 1024px) {
        min-height: 100vh;
        min-width: 450px;
        max-width: 27vw;
    }
    @media (max-width: 1024px) {
        width: 80vw;
        max-width: 500px;
    }
}

.contact-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.button {
    display: flex;
    flex-direction: row;
    height: 60px;
    width: 450px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 100px;
    margin-block: 30px;
    margin-inline: 5px;
    transition: all .15s ease-in;

    @media (max-width: 1024px) {
        height: 45px;
        width: 80vw;
        margin-block: 15px;
    }

    &:hover,
    &:active,
    &:focus {
        transform: scale3d(1.03, 1.02, 1)
    }
}

.button-icon {
    height: 45px;
    width: auto;
    margin-inline: 10px;
    @media (max-width: 1024px) {
        height: 35px;
    }
}

.blue-bg {
    background-color: #D4EAFF;
    box-shadow: 1px 1px 7px #5067FF, -1px -1px 7px #5067FF, -1px 1px 7px #5067FF, 1px -1px 7px #5067FF;
}

.pink-bg {
    background-color: #FDB2FF;
    box-shadow: 1px 1px 7px #E640E6, -1px -1px 7px #E640E6, -1px 1px 7px #E640E6, 1px -1px 7px #E640E6;
}

.button-text {
    color: #5067FF;
    font-family: "Jersey 15", sans-serif;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    font-style: normal;
    @media (max-width: 1024px) {
        font-size: 1.5rem;
    }
}

.blue {
    color: #5067FF;
}

.pink {
    color: #E640E6;
}