.about {
    min-height: 88vh;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
}

.about-container {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    padding: 1rem;
}

.about-image-container {
    height: auto;
    margin-block: 15px;
    @media (min-width: 1024px) {
        min-width: 400px;
        max-width: 25vw;
    }
    @media (max-width: 1024px) {
        width: 80vw;
        max-width: 500px;
    }
}

.about-image {
    width: 100%;
    height: auto;
}

.gif {
    height: 2.4rem;
    width: 100px;
    border-radius: 100px;
    object-fit: cover;
    margin-bottom: -10px;
    @media (max-width: 1024px) {
        height: 1.5rem;
        width: 80px;
    }
}

.text {
    color: white;
    font-family: "Chakra Petch", sans-serif;
    font-size: 2.2rem;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 3px darkgoldenrod;
    @media (max-width: 1024px) {
        font-size: 1.2rem;
    }
}
