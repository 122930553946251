.header {
    height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 4%;
}

.header-title {
    color: white;
    font-size: 3rem;
    font-family: "Jersey 15", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 3px darkgoldenrod;
    @media (max-width: 1024px) {
        font-size: 2.5rem;
    }
}

.header-text {
    color: white;
    font-size: 2.5rem;
    font-family: "Jersey 15", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 3px darkgoldenrod;
    transition: all .15s ease-in;

    &:hover,
    &:active,
    &:focus {
        color: grey;
        cursor: pointer;
    }
}

.header-sections {
    @media only screen and (max-width: 900px) {
        display: none;
    }
    display: flex;
    width: 350px;
    flex-direction: row;
    justify-content: space-between;
}

.header-menu-icon {
    @media only screen and (max-width: 900px) {
        display: block;
        height: 2.5rem;
        width: auto;
    }
    display: none;

    &:hover,
    &:active,
    &:focus {
        filter: grayscale(100%) brightness(70%);
        cursor: pointer;
    }
}

.text-centered {
    width: 100%;
    text-align: center;
}

.margin-vertical {
    margin-block: 0.2em;
}